.ion-page {
  background-color: #ffffff;
}
.fade {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.no-shadow {
  box-shadow: 0 0 transparent;
}
.ratio-square {
  border-radius: 4px;
  width: 100%;
  padding-top: 100%;
  position: relative;
}
.ratio-square * {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.ratio-41 {
  border-radius: 4px;
  width: 100%;
  padding-top: 25%;
  position: relative;
}
.ratio-41 * {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.ratio-31 {
  border-radius: 4px;
  width: 100%;
  padding-top: 33.333333333333336%;
  position: relative;
}
.ratio-31 * {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.absolute-center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.m-2 {
  margin: 2px;
}
.m-0 {
  margin: 0;
}
.p-0 {
  padding: 0;
}
.p-10 {
  padding: 10px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-top: 20px;
}
.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.w-100 {
  widows: 100%;
}
.bg-red {
  background-color: red;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
/* RIBBON */
.ribbon-product {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  /* left: 0; */
  text-align: center;
  color: #c74643;
  font-weight: bold;
  background: #ffe54b;
}
.ribbon-product:before,
.ribbon-product:after {
  z-index: 1;
  content: "";
  position: absolute;
}
.ribbon-product:after {
  top: 29px;
  left: 0;
  border-top: 10px solid #ffe54b;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #ffe54b;
  border-right: 15px solid #ffe54b;
}
.product-caption {
  padding: 5px;
}
.product-caption .price-regular {
  font-size: 14px;
  line-height: 1;
  color: #f29e1f;
  font-weight: bold;
}
.product-caption .product-name {
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  height: 30px;
  font-size: 12px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media only screen and (max-width: 767px) {
  .product-caption .product-title {
    margin-left: 10;
    color: #252158;
    font-weight: bold;
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .product-caption .product-title {
    margin-left: 10;
    color: #252158;
    font-weight: bold;
    font-size: 24px;
  }
}

.progress {
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  height: 16px;
  width: 100%;
}

.progress-done {
  background: linear-gradient(to left, #f7840b, #efff00);
  box-shadow: 0 3px 3px -5px #f7840b, 0 2px 5px #efff00;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
  color: #fff;
  display: flex;
  font-size: 10px;
}
.progress-label {
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
  width: 100%;
  color: #fff;
  display: flex;
  font-size: 10px;
  font-weight: bold;
}

.otpContainer {
  margin: 5% auto;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.modal-sheet .modal-wrapper {
  height: 40%;
  top: 60%;
  position: absolute;
  display: block;
}
.modal-sheet-kodepos .modal-wrapper {
  height: 70%;
  top: 30%;
  position: absolute;
  display: block;
}

.slick-list > div {
  margin-left: 0;
}
.ReactCreditCard__container {
  width: auto!important;
}